import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface CityPart {
    id:number,
    cityName:string,
    originalCityPartName:string
}

export interface CityParts {
    items:CityPart[],
    loading:boolean
}

const initialState: CityParts = {
    items:[],
    loading:false
}

const cityPartReducer = (state: CityParts = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.LOAD_CITY_PARTS:
            return action.payload;
        default:
            return state;
    }
}

export default cityPartReducer;