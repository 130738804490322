import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams, } from "react-router-dom";
import { State } from "../../state";
import { HeaderOfContent } from "./Header";
import { Main } from "./Main";
import { Pagination } from "./Pagination";
import { Filter } from "../Filter/Filter";

export const Content = () => {
    const apartments = useSelector((state: State) => state.apartments);

    const header = apartments.items !== undefined && <HeaderOfContent />;
    const pagination = apartments.items !== undefined && apartments.items.length > 0 && <Pagination />;

    const path = useLocation().pathname;
    
    const [params] = useSearchParams();
    const duplicate_group = params.get("duplicate_group") && "Duplikati oglasa";
    
    const showFilter = !params.get("duplicate_group") && !path.includes("favoriti") && !path.includes("obrisani"); 
    
    return (
        <>
            { showFilter && <Filter /> }
            <div className="container" id="rezultati">
                {header}
                <h5>{duplicate_group}</h5>
                <Main />
                {pagination}
            </div>
        </>

    )
}