import { combineReducers } from "redux";
import apartmentReducer from "./apartmentReducer"
import cityReducer from "./cityReducer"
import cityPartReducer from "./cityPartReducer"
import locationReducer from "./locationReducer";
import filterReducer from "./filterReducer"
import loadingReducer from "./loadingReducer";
import modalReducer, { listingModalReducer } from "./modalReducer";
import duplicatesReducer from "./duplicatesModalReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
    apartments: apartmentReducer,
    filter: filterReducer,
    modal: modalReducer,
    listingModal: listingModalReducer,
    loading: loadingReducer,
    cities: cityReducer,
    cityParts: cityPartReducer,
    locations: locationReducer,
    duplicates: duplicatesReducer,
    user: userReducer
})

export default reducers;

export type State = ReturnType<typeof reducers>;