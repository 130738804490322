import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface City {
    id:number,
    cityName:string,
}

export interface Cities {
    items:City[],
    loading:boolean
}

const initialState: Cities = {
    items:[],
    loading:false
}

const cityReducer = (state: Cities = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.LOAD_CITIES:
            return action.payload;
        default:
            return state;
    }
}

export default cityReducer;