import { Props } from "./Listing"

export const Description: React.FC<Props> = ({ item }: Props) => {

    return (
        <div className="row info-row">
            <p style={{ height: "6em" }}>
                {item.description == null ? "" : item.description}
            </p>
        </div>
    )
}