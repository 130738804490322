import { Props } from "./Listing"


export const Area: React.FC<Props> = ({ item }: Props) => {

    const area = item.area != null && <span className="area-span">{item.area} m<sup>2</sup></span>;

    const rooms = () => {
        if (item.rooms != null) {
            if (item.rooms as number >= 2 && item.rooms as number <= 4.5) {
                return (
                    <span className="room-span">{item.rooms} sobe</span>
                );
            }
            else if (item.rooms as number > 5) {
                return (
                    <span className="room-span">5+ soba</span>
                );
            }
            else {
                return (
                    <span className="room-span">{item.rooms} soba</span>
                );
            }
        }
    }

    // const baths = item.Baths == null ? '' : `<span class="bath-span">${item.Baths}</span>`;

    return (
        <div className="row info-row" style={{ height: "30px" }} >
            <div className="col-4 span-col" style={{ height: "30px" }}>
                {area}
            </div>

            <div className="col-4 span-col" style={{ height: "30px" }} >
                {rooms()}
            </div>
            {/* <div className="col-4 span-col" style={{ height: "30px" }} >
                {baths}
            </div>  */}
        </div>
    )
}