
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';
import { bindActionCreators } from 'redux';
import { actionsCreators, State } from '../../state';
import { filter } from '../Filter/Content';

export const Pagination = () => {
  const dispatch = useDispatch();

  const { changeFilterParams, loadApartments, changeIsLoading, changeListApartments } = bindActionCreators(actionsCreators, dispatch);

  const filterState = useSelector((state: State) => state.filter);
  const apartments = useSelector((state: State) => state.apartments);
  const loading = useSelector((state: State) => state.loading);

  const fieldRef = React.useRef<HTMLInputElement>(null);

  const path = useLocation().pathname;

  const hrefBuilder = () => {
    return `${path}/${filter(filterState)}`
  }
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const pageNumber = params.get("pageNumber");
  
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const showFilter = !params.get("duplicate_group") && !path.includes("favoriti") && !path.includes("obrisani");

  const id = () => {
    if(!showFilter){
      return "#rezultati";
    }else if(windowDimensions.height > 2000){
      return "#logo";
    }else if(windowDimensions.height > 1100){
      return "#tabs";
    }else if (windowDimensions.height > 950){
      return "#tip";
    }else if (windowDimensions.height > 600){
      return "#advanced_submit_4";
    }else{
      return "#rezultati";
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  return (
      <div className="row">
          <div style={{margin:"auto"}}>
              <div id="pagination-container">
              <div className="paginationjs paginationjs-theme-blue paginationjs-big">
                <div className="paginationjs-pages">
                <ScrollIntoView 
                  selector={id()}
                  >
                <ReactPaginate
                        breakLabel="..."
                        nextLabel="»"
                        forcePage={Number(pageNumber)-1}
                        onPageChange={(event) => {
                          setWindowDimensions(getWindowDimensions());
                          // filterState.stateOfFilter.duplicate_group = "";
                          filterState.stateOfFilter.pageNumber = (event.selected+1).toString();
                          apartments.items=[];
                          apartments.totalCount = -1;
                          changeListApartments(apartments);
                          loadApartments(filterState, path);
                          changeFilterParams(filterState);
                          loading.isLoading = true;
                          changeIsLoading(loading);
                          navigate(`${path}?${filter(filterState)}`);
                                }}
                          pageRangeDisplayed={2}
                          marginPagesDisplayed={1}
                          pageCount={Math.ceil(apartments.totalCount/18)}
                          previousLabel="«"
                          renderOnZeroPageCount={undefined}
                          disabledClassName = "disabled"
                          pageClassName = "paginationjs-page J-paginationjs-page"
                          activeClassName = "paginationjs-page J-paginationjs-page active"
                          previousClassName = "paginationjs-prev J-paginationjs-previous"
                          nextClassName = "paginationjs-next J-paginationjs-next"
                          hrefBuilder = {hrefBuilder}
                              />
                </ScrollIntoView>
                  </div>
                </div>
              </div>
          </div>
      </div>
      )
}