import React, { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { Item, } from '../../state/reducers/apartmentReducer'
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { Option } from "../../state/reducers/filterReducer"; 
import { useDispatch, useSelector } from "react-redux";
import { GetById, addListing, updateListing } from '../../services/listingService';
import { stepSectionProps } from './AddListingSection';
import { ImCross } from "react-icons/im";
import { useParams } from 'react-router-dom';
import { uploadPictures } from '../../services/pictureService';

interface Input {
    listingType: string,
    title: string,
    city: string,
    cityPart?: string,
    price: number,
    area: number,
    rooms: string,
    description: string,
    izdaje: string
    street: string | null,
    terrace: boolean,
    airCon: boolean,
    elavator: boolean,
    floor: number | null,
    pictures: File[]
    furnishingType: string,
    heatingType: string,
}



function AddListingForm({ onPrevious, selectedType }: stepSectionProps) {
    const { register, handleSubmit, getValues, setValue, formState: { errors }, control } = useForm<Input>(
        {
            defaultValues: {
                izdaje: 'rent',
                cityPart: 'Nije navedeno',
                rooms: '',
                street: null,
                floor: null,
                furnishingType: 'NijeNavedeno',
                heatingType: 'NijeNavedeno',
                airCon:false,
                elavator:false,
                terrace:false
            }
        }
    );

    const dispatch = useDispatch();
    const { loadCities, isVisibleModal, loadCityParts } = bindActionCreators(actionsCreators, dispatch);
    const cityState = useSelector((state: State) => state.cities);
    const cityPartsState = useSelector((state: State) => state.cityParts);
    const modal = useSelector((state: State) => state.modal);
    const userState = useSelector((state: State) => state.user);

    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [id, setId] = useState(0);
    const [newPictures, setNewPictures] = useState(false);
    const params = useParams();

    const handleImageChange = (e: any) => {
        if (e.target.files) {
            const newImages = Array.from([...e.target.files]).map((file) => URL.createObjectURL(file));
            setSelectedImages((prevImages) => [...prevImages, ...newImages]);
            setNewPictures(true);
        }
    };

    const krajevi = cityPartsState.items !== undefined ? cityPartsState.items.map((cityPart, index) => {
        return <option key={index} value = {cityPart.originalCityPartName} />
    }) : [];

    const gradovi = cityState.items !== undefined ? cityState.items.map((city, index) => {
        return <option key={index} value = {city.cityName} />
    }) : [];

    const changeCityParts = (p: BaseSyntheticEvent) => {
        loadCityParts(getValues('city') ? getValues('city').trim() : "", p.target.value);
    }

    const changeCities = (p: BaseSyntheticEvent) => {
        loadCities(p.target.value);
        loadCityParts(p.target.value, "");
    }

    const saveListing = (formData: Input) => {
        const listing: Item = {
            id: id,
            title: getValues("title"),
            city: getValues('city'),
            cityPart: getValues("cityPart")!,
            price: getValues("price"),
            area: Number(getValues("area"))!,
            rooms: getValues("rooms"),
            description: getValues("description"),
            pictures: "",
            created: null,
            updated: null,
            active: false,
            scraped: false,
            url: "https://www.nadji.co.rs/",
            date: null,
            duplicateGroup: null,
            parsed: 0,
            action: getValues('izdaje')!,
            type: selectedType?.value!,
            website: "https://www.nadji.co.rs/",
            activePictureIndex: 0,
            duplicates: "",
            favorite: false,
            undesirable: false,
            email:userState.state.logged.email,
            airCondition: getValues('airCon'),
            elavator: getValues('elavator'),
            floor: getValues('floor'),
            furnishingType: getValues('furnishingType'),
            heatingType: getValues('heatingType'),
            street: getValues("street"),
            terrace: getValues('terrace')
        };

        const pictures = Array.from(formData.pictures)
        if (pictures.length > 0 && newPictures) {
            try {
                const filteredImages = selectedImages.filter(i => !i.includes("blob"));
                uploadPictures(pictures).then(res => {
                    listing.pictures = `${filteredImages.join(", ")}, ${res.data}`;
                    const submit = id !== 0 ? updateListing(listing) : addListing(listing);
                    submit.then(res => {
                        setId(res.data);
                        modal.state.alert.messages = ["Uspešno ste sačuvali oglas."];
                        modal.state.alert.isVisibleAlert = true;
                        isVisibleModal({ state: modal.state });
                    });
                })
            }
            catch (err: any) {
                modal.state.alert.messages = [err.response.data];
                modal.state.alert.isVisibleAlert = true;
                isVisibleModal({ state: modal.state });
            }
        }
        else {
            try {
                listing.pictures = selectedImages.join(", ");
                const submit = id !== 0 ? updateListing(listing) : addListing(listing);
                submit.then(res => {
                    setId(res.data)
                    modal.state.alert.messages = ["Uspešno ste sačuvali oglas."];
                    modal.state.alert.isVisibleAlert = true;
                    isVisibleModal({ state: modal.state });
                });
            }
            catch (err: any) {
                modal.state.alert.messages = [err.response.data];
                modal.state.alert.isVisibleAlert = true;
                isVisibleModal({ state: modal.state });
            }
        }
    }

    const FurnishTypes: Option[] = [
        {
            value: "0",
            optionContent: "Namešten"
        },
        {
            value: "1",
            optionContent: "Polu nameštem"
        },
        {
            value: "2",
            optionContent: "Nije namešten"
        },

    ];

    const HeatingTypes: Option[] = [
        {
            value: "0",
            optionContent: "Gradsko grejanje"
        },
        {
            value: "1",
            optionContent: "Grejanje na gas"
        },
        {
            value: "2",
            optionContent: "Grejanje na struju"
        },
        {
            value: "3",
            optionContent: "Kombinovano grejanje"
        },
        {
            value: "4",
            optionContent: "Grejanje na čvrsta goriva"
        },
        {
            value: "5",
            optionContent: "Podno grejanje"
        },
        {
            value: "6",
            optionContent: "Kaljeva peć"
        },
        {
            value: "7",
            optionContent: "Ostalo"
        },

    ];
    
    const sobe: Option[] = [
        {
            value: "0.5",
            optionContent: "0.5"
        },
        {
            value: "1.0",
            optionContent: "1.0"
        },
        {
            value: "1.5",
            optionContent: "1.5"
        },
        {
            value: "2.0",
            optionContent: "2.0"
        },
        {
            value: "2.5",
            optionContent: "2.5"
        },
        {
            value: "3.0",
            optionContent: "3.0"
        },
        {
            value: "3.5",
            optionContent: "3.5"
        },
        {
            value: "4.0",
            optionContent: "4.0"
        },
        {
            value: "4.5",
            optionContent: "4.5"
        },
        {
            value: "5.0+",
            optionContent: "5.0+"
        },
    ];

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    const removeSelectedImg = (image:string) => {
        const index = selectedImages.indexOf(image) - selectedImages.filter(img => !img.includes("blob")).length;
        if(image.includes('blob')){
            const files = Array.from(getValues('pictures')).filter((f,i) => i !== index );
            setValue('pictures', files);
        }
        const filteredList = selectedImages.filter(i => i !== image);
        setSelectedImages(filteredList);
    }

    useEffect(() => {
        if(params.id){
            setId(Number(params.id));
            GetById(Number(params.id)).then(res => {
                const item:Item = res.data;
                setSelectedImages(item.pictures.split(", ").filter(pic => pic.trim() !== ""));
                setValue('area', item.area!);
                setValue('title', item.title);
                setValue('city', item.city);
                setValue('cityPart', item.cityPart);
                setValue('description', item.description);
                setValue('rooms', Number(item.rooms!.toString()).toPrecision(2));
                setValue('listingType', item.type);
                setValue('izdaje', item.action);
                setValue('price', item.price!);
                setValue('airCon', item.airCondition);
                setValue('elavator', item.elavator);
                setValue('terrace', item.terrace);
                setValue('floor', item.floor);
                setValue('furnishingType', item.furnishingType);
                setValue('heatingType', item.heatingType);
                setValue('street', item.street);
                loadCities(item.city);
            });
        }else{
            loadCities("");
        }

    }, []);

    const showSelectedImages = selectedImages.length > 0 &&
    selectedImages.map((image, index) => {
        return(
            <div key={index++} className='prewiev-img'>
                <ImCross className="upload-pictures-x" size={8} onClick={() => removeSelectedImg(image)}/>
                <img
                    src={image}
                    alt="preview"
                    style={{ width: "80px", border: "1px solid black", height: "auto" }}
                    />
            </div>
        )
    });

    return (
        <div className='text-center d-flex flex-column'>
            <h3 className='form-title'>Unesite potrebene podatke</h3>
            <div className="form-text mb-2 text-muted">(Polja označena znakom * su obavezna)</div>
            <form className='add-listing-form ' onSubmit={handleSubmit((formData) => { saveListing(formData) })} onKeyPress={handleKeyPress}>
                <div className="form-check-inline fs-24">
                    <input className='form-check-input' type='radio' id="izdaje" value={'rent'} {...register("izdaje")}></input>
                    <label htmlFor='izdaje' className='form-check-label'>Izdajem</label>
                </div>
                <div className="form-check-inline fs-24 mb-3">
                    <input type='radio' className="form-check-input" id="prodaje" value={'sale'} {...register("izdaje")}></input>
                    <label htmlFor='prodaje' className="form-check-label">Prodajem</label>
                </div>
                {errors.title && (
                    <div className="alert alert-danger m-3" role="alert">
                        Morate uneti naslov!
                    </div>)}
                <input className={`${errors.title ? 'invalid ' : ''}form-control mb-3`} placeholder={"* Unesite naslov oglasa"}  {...register("title", { required: true })}></input>
                {errors.city && (
                    <div className="alert alert-danger m-3" role="alert">
                        Morate izabrati grad!
                    </div>)}
                <input type="text" 
                        id="gradovi" 
                        className={`${errors.city ? 'invalid ' : ''}form-control mb-3`}
                        list="dataGradovi"
                        onSelect={(s) => { setValue('city', s.currentTarget.value) }}
                        {...register("city", { onChange: changeCities, required: true, minLength: 2 })}
                        placeholder={"Unesite mesto"}
                        />
                <datalist id="dataGradovi">
                    {gradovi}
                </datalist>
                <input className='form-control mb-3' type="text" 
                        id="krajevi" 
                        list="dataKrajevi"
                        onSelect={(s) => { setValue('cityPart', s.currentTarget.value) }}
                        {...register("cityPart", { onChange: changeCityParts, required: false })}
                        placeholder={"Delovi grada"}
                />
                <datalist id="dataKrajevi">
                    {krajevi}
                </datalist>
                <input className={`${errors.title ? 'invalid ' : ''}form-control mb-3`} placeholder={"Unesite ulicu"}  {...register("street", { required: false })}></input>
                {errors.price && (
                    <div className="alert alert-danger m-3" role="alert">
                        Morate uneti ispravnu cenu!
                    </div>)}
                <input className={`${errors.price ? 'invalid ' : ''}form-control mb-3`} placeholder={"* Unesite cenu u €"} {...register("price", { required: true, min: 0 })} type='number'></input>
                {errors.area && (
                    <div className="alert alert-danger m-3" role="alert">
                        Morate uneti ispravnu površinu!
                    </div>)}
                <input className={`${errors.street ? 'invalid ' : ''}form-control mb-3`} placeholder={"* Unesite površinu u m2"} {...register("area", { required: true, min: 5 })} type='number' ></input>
                {selectedType?.value !== 'land' && selectedType?.value !== 'garage' && (
                    <>
                        {errors.rooms && (
                            <div className="alert alert-danger m-3" role="alert">
                                Morate izabrati broj soba!
                            </div>)}
                        <select defaultValue={getValues('rooms')} className={`${errors.rooms ? 'invalid ' : ''}form-control mb-3`} {...register("rooms", { required: true })}>
                            <option disabled value="">* Unesite broj soba</option>
                            {sobe.map(soba => (
                                <option key={soba.value} value={soba.value}>{soba.optionContent}</option>
                            ))}
                        </select>
                        <input className={`${errors.floor ? 'invalid ' : ''}form-control mb-3`} placeholder={"Unesite sprat"} {...register("floor", { required: false, min: 0 })} type='number'></input>
                        <select defaultValue={"NijeNavedeno"} className={`${errors.heatingType ? 'invalid ' : ''}form-control mb-3`} {...register("heatingType", { required: false })}>
                            <option value="NijeNavedeno">Tip grejanja</option>
                            {HeatingTypes.map(type => (
                                <option key={type.value} value={type.value}>{type.optionContent}</option>
                            ))
                            }
                        </select>
                        <select defaultValue={"NijeNavedeno"} className={`${errors.heatingType ? 'invalid ' : ''}form-control mb-3`} {...register("furnishingType", { required: false })}>
                            <option value="NijeNavedeno">Opremljenost</option>
                            {FurnishTypes.map(type => (
                                <option key={type.value} value={type.value}>{type.optionContent}</option>
                            ))
                            }
                        </select>
                        <div className='d-flex flex-column align-items-start p-3'>
                            <div className="form-check fs-24 d-flex align-items-center">
                                <input className='form-check-input' type='checkbox' id="airCon" {...register("airCon")}></input>
                                <label htmlFor='airCon' className='form-check-label'>Ima klimu</label>
                            </div>
                            <div className="form-check fs-24  d-flex align-items-center">
                                <input className='form-check-input' type='checkbox' id="terrace" {...register("terrace")}></input>
                                <label htmlFor='terrace' className='form-check-label'>Ima terasu</label>
                            </div>
                            <div className="form-check fs-24  d-flex align-items-center">
                                <input className='form-check-input' type='checkbox' id="elavator" {...register("elavator")}></input>
                                <label htmlFor='elavator' className='form-check-label'>Ima lift</label>
                            </div>
                        </div>
                    </>
                )}
                {errors.description && (
                    <div className="alert alert-danger m-3" role="alert">
                        Morate uneti opis svog naslova!
                    </div>)}
                <textarea className={`${errors.description ? 'invalid ' : ''}form-control mb-3`} placeholder={"* Unesite tekst oglasa"} {...register("description", { required: true })} rows={12}></textarea>
                <div className="container d-flex flex-column flex-md-row align-items-center mb-3">
                    <label htmlFor="file" className="choose-img input-file-color mr-auto">
                        Unesite slike
                    </label>
                    <input  {...register("pictures", { onChange: handleImageChange })} name='pictures' id="file" multiple={true} className="input-file" type="file" accept="image/png, image/jpeg" />
                    <div className="grid grid-4-col" style={{ gap: "2px" }}>
                        {showSelectedImages}
                    </div>
                </div>
                <div className='d-flex'>
                    <button type='button' className='btn btn-outline-primary fs-20' onClick={onPrevious}>Predhodni korak</button>
                    <button className='btn btn-primary fs-20 ml-auto' type='submit' >Sačuvaj oglas</button>
                </div>
            </form>
        </div >
    )
}

export default AddListingForm