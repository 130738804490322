import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators, State } from "../../state";
import { ParamsOfSearch } from "../../state/reducers/filterReducer";

export interface Props {
    name: string,
    placeholder: string
}

export const InputParamFilter: React.FC<Props> = ({ name, placeholder }: Props) => {
    const dispatch = useDispatch();

    const { changeFilterParams } = bindActionCreators(actionsCreators, dispatch);

    const filterState = useSelector((state: State) => state.filter);

    return (
        <div className="form-group col-lg-2 col-md-6 col-6 red_design">
            <div className={name.includes("cena") ? "euros symbol":"meters symbol"}>
                <input 
                    type="number" 
                    min="0" 
                    className="form-control" 
                    name={name} 
                    placeholder={placeholder} 
                    onChange={(event) => {
                        filterState.stateOfFilter[name as keyof ParamsOfSearch] = event.target.value;
                        changeFilterParams({stateOfFilter:filterState.stateOfFilter});
                    }}
                    value = {filterState.stateOfFilter[name as keyof ParamsOfSearch]!}/>
            </div>
        </div>
        )
}