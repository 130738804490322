import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface Location {
    id:number,
    extensivLocationId:number,
    name:string,
    city:string,
    quarter:string,
    county:string,
    state:string,
    country:string,
    type:string
}

export interface Locations {
    items:Location[],
    loading:boolean
}

const initialState: Locations = {
    items:[],
    loading:false
}

const locationReducer = (state: Locations = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.CHANGE_LOCATIONS:
            return action.payload;
        default:
            return state;
    }
}

export default locationReducer;