import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface ParamsOfSearch {
    tip:string,
    grad:string,
    krajevi:string,
    location:string,
    min_kvadratura:string,
    max_kvadratura:string,
    min_cena:string,
    max_cena:string,
    min_soba:string,
    max_soba:string,
    sortiranje:string,
    duplicate_group: string | null,
    akcija:string,
    pageNumber:string
}

const initialState: StateOfFilter = {
    stateOfFilter:{
        tip:"Stan",
        grad:"",
        krajevi:"",
        location:"",
        min_kvadratura:"",
        max_kvadratura:"",
        min_cena:"",
        max_cena:"",
        min_soba:"",
        max_soba:"",
        sortiranje:"DatumO",
        duplicate_group: "",
        akcija:"Prodaja",
        pageNumber:"1"
    }
}

export interface ItemOfFilter{
    name:string,
    value:string
}

export interface Option {
    value: string;
    optionContent: string;
}

export interface StateOfFilter {
    stateOfFilter:ParamsOfSearch
}

const filterReducer = (state: StateOfFilter = initialState, action: Action) => {

    switch (action.type) {
        case ActionType.CHANGE_PARAMS:
            return action.payload;
        default:
            return state;
    }
}

export default filterReducer;