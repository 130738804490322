export enum ActionType {
    LOAD_APARTMENTS = "load_apartments",
    CHANGE_PARAMS = "change_params",
    IS_VISIBLE_MODAL = "is_visible_modal",
    IS_VISIBLE_L_MODAL = "is_visible_l_modal",
    IS_LOADING = "is_loading",
    CHANGE_LIST_APARTMETS = "change_list_apartmets",
    LOAD_CITIES = "load_cities",
    LOAD_CITY_PARTS = "load_city_parts",
    CHANGE_LOCATIONS = "change_locations",
    IS_VISIBLE_DUPLICATES = "is_visible_duplicates",
    CHNAGE_USER_STATE = "change_user_state"
}